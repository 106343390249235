<template>
  <AuthLayout :img-url="imgUrl">
    <div class="d-flex flex-column gap-20">
      <b-card-title title-tag="h2" class="font-weight-bold text-center mb-1"> Lütfen Firma Seçiniz </b-card-title>
      <b-list-group>
        <b-list-group-item v-for="item in companies" @click="selectCompany(item)" :key="item._id" button>{{ item.name }}</b-list-group-item>
      </b-list-group>
      <app-button @click="redirectLogin" class="mt-1" text="Çıkış Yap" block />
    </div>
  </AuthLayout>
</template>

<script>
/* eslint-disable global-require */
import AuthLayout from "./AuthLayout.vue";

export default {
  components: {
    AuthLayout,
  },
  computed: {
    imgUrl() {
      const { skin } = this.$store.state.appConfig.layout;
      return skin === "dark" ? require("@/assets/images/pages/coming-soon-dark.svg") : require("@/assets/images/pages/coming-soon.svg");
    },
    companies() {
      return this.$store.getters?.user?.companies;
    },
  },
  methods: {
    redirectLogin() {
      this.$store.dispatch("logout", { sendLogoutReq: true });
    },
    selectCompany(item) {
      this.$store.commit("setSelectedCompany", item);
      this.$router.push({ name: "customer-dashboard" });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
